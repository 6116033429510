<template>
  <div class="pa-3">
    <v-dialog v-model="dialogAguardar" width="20vw" persistent>
      <v-card>
        <v-card-title>Aguarde...</v-card-title>
        <v-card-text>
          <v-progress-circular
            indeterminate
            size="16"
            class="center"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="d-flex justify-center align-center">
      <v-row no-gutters>
        <v-col cols="1" class="ma-2">
          <v-select
            class="rounded-lg"
            v-model="anoEscolhido"
            :loading="loadingAnos"
            :items="anos"
            label="Escolha o ano"
            @change="buscaPessoas"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="2" class="ma-2">
          <v-select
            class="rounded-lg"
            v-model="trimestre"
            :items="[
              { text: '1 trimestre', value: '1' },
              { text: '2 trimestre', value: '2' },
              { text: '3 trimestre', value: '3' },
              { text: '4 trimestre', value: '4' },
            ]"
            label="Escolha o Trimestre"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="5" class="ma-2">
          <v-autocomplete
            class="rounded-lg"
            v-model="prospector"
            item-text="text"
            item-value="value"
            :items="prospectores"
            label="Escolha o prospector"
            @change="buscaMedicos"
            :disabled="prospectores.length <= 0"
            v-on:keyup.enter="calcula"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
    </div>

    <v-data-table
      v-model="selecao"
      :headers="headers"
      :items="medicos"
      item-key="A3_COD"
      show-select
      class="elevation-1"
      dense
      height="75vh"
      :items-per-page="medicos.length"
      fixed-header
      no-data-text="Sem dados no momento"
      hide-default-footer
      :search="search"
    >
      <template v-slot:top>
        <div
          class="d-flex justify-space-around align-center"
          v-if="medicos.length > 0"
        >
          <v-badge color="black darken-2" :content="totalGeral">
            <div class="d-flex flex-column align-center">
              <span class="font-weight-bold black--text text--darken-2"
                >Geral</span
              >
            </div>
          </v-badge>
          <v-badge color="blue darken-2" :content="totalSelecao">
            <div class="d-flex flex-column align-center">
              <span class="font-weight-bold blue--text text--darken-2"
                >Selecionado</span
              >
            </div>
          </v-badge>
          <v-col cols="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-btn
            class="primary"
            x-small
            @click="salvaPeriodo"
            :disabled="periodoFechado"
            >Salvar</v-btn
          >
          <span class="text-h4 red--text" v-if="periodoFechado"
            >Período fechado pelo administrador!</span
          >
          <v-btn
            v-if="user.isAdmin && !periodoFechado"
            color="orange"
            dark
            x-small
            @click="fechaPeriodo"
            >Fechar período</v-btn
          >
          <v-btn
            v-if="user.isAdmin && periodoFechado"
            color="cyan"
            dark
            x-small
            @click="AbrePeriodo"
            >Abrir período</v-btn
          >
          <v-tooltip bottom>
            <v-spacer></v-spacer>
            <template v-slot:activator="{ on, attrs }">
              <vue-excel-xlsx
                :data="itemsRelatExcel"
                :columns="headersRelatExcel"
                :filename="nameReal"
                sheetname="Dados"
                class="ml-5 mb-5 tbExcel"
              >
                <v-btn
                  :disabled="selecao.length <= 0"
                  icon
                  color="blue darken-2"
                  class="mr-5 mt-5"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-microsoft-excel</v-icon>
                </v-btn>
              </vue-excel-xlsx>
            </template>
            <span>Exportar para excel a seleção</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
      :color="colorSnack"
      multi-line="multi-line"
      :timeout="6000"
      bottom="bottom"
    >
      <v-icon small color="#FFF" class="mr-3">{{ icon }}</v-icon>
      {{ mensagem }}
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
import { add, sub } from "date-fns";
import { mapState } from "vuex";

export default {
  name: "PainelVisit",
  computed: {
    totalSelecao() {
      return this.selecao.length;
    },
    ...mapState({
      user: state => state.user,
    }),
    itemsRelatExcel() {
      return this.selecao;
    },
  },
  data: () => {
    return {
      dialogAguardar: false,
      headersRelatExcel: [],
      nameReal: null,
      snackbar: false,
      colorSnack: "red",
      search: null,
      icon: null,
      mensagem: null,
      anoEscolhido: null,
      loadingAnos: false,
      trimestre: null,
      prospector: null,
      prospectores: [],
      anos: [],
      selecao: [],
      headers: [
        { text: "Código", value: "A3_COD" },
        { text: "Nome", value: "A3_NOME" },
        { text: "Município", value: "A3_MUN" },
      ],
      medicos: [],
      totalGeral: 0,
      periodoFechado: false,
    };
  },
  methods: {
    async salvaPeriodo() {
      this.dialogAguardar = true;
      const url = `${process.env.VUE_APP_BASE_API_URL}/salvaPainelVisita`;

      const result = await axios.post(url, {
        selecao: this.selecao,
        prospector: this.prospector.split("-")[0],
        ano: this.anoEscolhido,
        trimestre: this.trimestre,
      });

      this.dialogAguardar = false;

      if (!result || result.status !== 200) {
        this.colorSnack = "red";
        this.icon = "mdi-close";
        this.mensagem = "Erro ao salvar painel";
        this.snackbar = true;
        return;
      }

      this.colorSnack = "green darken-4";
      this.icon = "mdi-check";
      this.mensagem = "Painel salvo com sucesso";
      this.snackbar = true;
    },
    async buscaAnos() {
      const anosSub = sub(new Date(), { years: 2 });

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anosSub, { years: cont }).getFullYear() <=
          new Date().getFullYear()
        ) {
          this.anos.push(add(anosSub, { years: cont }).getFullYear());
        }
      }

      this.anos = this.anos.reverse();
      this.loadingAnos = false;
    },
    async buscaPessoas() {
      const url = `${process.env.VUE_APP_BASE_API_URL}/buscaProspectores`;

      const prospectores = await axios.get(url);

      if (!prospectores || prospectores.status !== 200) {
        this.colorSnack = "red";
        this.icon = "mdi-close";
        this.mensagem = "Erro ao burcar prospectores";
        this.snackbar = true;
        return;
      }

      this.prospectores = prospectores.data.map(e => {
        return {
          text: `${e.A3_COD}-${e.A3_NOME}`,
          value: `${e.A3_COD}-${e.A3_ZTIPO}`,
        };
      });
    },
    async buscaMedicos() {
      this.medicos = [];
      this.totalGeral = 0;
      const url = `${process.env.VUE_APP_BASE_API_URL}/buscaMedicos`;

      const medicos = await axios.post(url, {
        prospector: this.prospector.split("-")[0],
        tipo: this.prospector.split("-")[1],
      });

      this.medicos = medicos.data;

      this.totalGeral = medicos.data.length;

      this.headersRelatExcel = Object.keys(medicos.data[0]).map(e => {
        return {
          label: e,
          field: e,
        };
      });

      this.nameReal = `Relat ano ${this.anoEscolhido} trimestre ${
        this.trimestre
      } prospector ${this.prospector.split("-")[0]}`;
      await this.buscaVisitas();
    },
    async fechaPeriodo() {
      const url = `${process.env.VUE_APP_BASE_API_URL}/fechaPeriodo`;

      const result = await axios.post(url, {
        prospector: this.prospector.split("-")[0],
        ano: this.anoEscolhido,
        trimestre: this.trimestre,
      });

      if (!result || result.status !== 200) {
        this.colorSnack = "red";
        this.icon = "mdi-close";
        this.mensagem = "Erro ao fechar o periodo";
        this.snackbar = true;
        return;
      }

      this.colorSnack = "green darken-4";
      this.icon = "mdi-check";
      this.mensagem = "Periodo fechado com sucesso";
      this.snackbar = true;

      this.periodoFechado = true;
    },
    async AbrePeriodo() {
      const url = `${process.env.VUE_APP_BASE_API_URL}/abrePeriodo`;

      const result = await axios.post(url, {
        prospector: this.prospector.split("-")[0],
        ano: this.anoEscolhido,
        trimestre: this.trimestre,
      });

      if (!result || result.status !== 200) {
        this.colorSnack = "red";
        this.icon = "mdi-close";
        this.mensagem = "Erro ao abrir o periodo";
        this.snackbar = true;
        return;
      }

      this.colorSnack = "green darken-4";
      this.icon = "mdi-check";
      this.mensagem = "Periodo aberto com sucesso";
      this.snackbar = true;

      this.periodoFechado = false;
    },
    async buscaVisitas() {
      this.periodoFechado = false;
      const url = `${process.env.VUE_APP_BASE_API_URL}/consultaPeriodoVisita`;

      const result = await axios.post(url, {
        prospector: this.prospector.split("-")[0],
        ano: this.anoEscolhido,
        trimestre: this.trimestre,
      });

      if (!result || result.status !== 200) {
        this.colorSnack = "red";
        this.icon = "mdi-close";
        this.mensagem = "Erro ao consultar visitas do periodo";
        this.snackbar = true;
        return;
      }

      result.data.map(e => {
        this.medicos
          .filter(m => m.A3_COD === e.ZXH_MEDICO)
          .map(m => {
            this.selecao.push(m);
          });
      });

      if (result.data.filter(e => e.ZXH_FECHA === "S").length > 0) {
        this.periodoFechado = true;
      }
    },
  },
  mounted() {
    this.buscaAnos();

    // this.buscaVendedores();
  },
};
</script>
<style></style>
